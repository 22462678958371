import { PureComponent } from 'react';
import styled from 'styled-components';
import { Container, ContainerContentStandard } from '../../../shared/styles/container';
import { device } from '../../../shared/styles/device';
import Layout from '../components/global/Layout';
import Seo from '../components/global/Seo';

export const TextOnlyPageContainer = styled.div`
  min-height: 100vh;
  overflow: hidden;
  width: 100%;

  h1 {
    font-size: 48px;
    font-weight: 800;
    line-height: 78px;
    margin-top: 0;
  }
  h2, h3 {
    font-size: 20px;
    line-height: 43px;
    margin: 0;
    word-break: break-word;
  }

  @media ${device.tablet} {
      h1 {
        font-size: 68px;
      }
      h2, h3 {
     font-size: 26px;
    }
  }

  section {
    margin-top: 64px;
  }
`;

export const Subheadline = styled.div`
    display: flex;
    flex-direction: row;
`;
export const WhiteLine = styled.div`
    background-color: ${(props) => props.theme.colors.primary};
    margin-right: 16px;
    min-width: 4px;
        @media ${device.tablet} {
      margin-right: 28px;
  }
`;
export const ContactGrid = styled.div`
  display: flex;
  flex-direction: column;
`;
export default class Imprint extends PureComponent {
  render() {
    const title = 'Impressum';
    const description = 'Impressum | STELL-MICH-EIN';

    return (
      <>
        <Seo title={title} description={description} />
        <Layout preview={false}>
          <TextOnlyPageContainer>
            <Container>
              <ContainerContentStandard>
                <ContactGrid>
                  <div>
                    <h1>Impressum</h1>
                    <Subheadline>
                      <WhiteLine />
                      <h2>
                        STELL-MICH-EIN ist ein Projekt der
                        SCM – School for Communication and Management
                      </h2>
                    </Subheadline>
                  </div>
                  <section>
                    <p>
                      <b>prismus communications GmbH</b>
                    </p>
                    <p>Lehmbruckstraße 24</p>
                    <p>10245 Berlin</p>
                    <p>Telefon 030 47989789</p>
                    <p>www.scmonline.de</p>
                    <p>info@scmonline.de</p>
                    <p>Geschäftsführer: Lars Dörfel</p>
                    <p>Registergericht: Amtsgericht Charlottenburg</p>
                    <p>Registernummer: HRB: 108989B</p>
                  </section>
                  <section>
                    <p>
                      Umsatzsteuer-Identifikationsnummer: DE 255 188 453 (gemäß § 27 a Umsatzsteuergesetz)
                    </p>
                    <p>
                      Inhaltlich Verantwortliche: siehe Geschäftsführer (gemäß § 6 MDStV)
                    </p>
                  </section>
                  <section>
                    <p>
                      Haftungshinweis: Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.
                    </p>
                  </section>
                  <section>
                    <b>Bildnachweis: Die Bilder stammen aus folgenden Quellen:</b>
                    <ul>
                      <li>Unsplash - https://unsplash.com/</li>
                      <li>Pexels - https://www.pexels.com/</li>
                    </ul>
                  </section>
                  <section>
                    <b>Software Entwicklung Talentpool: Christoph Meise</b>
                    <p>https://explorechristoph.com/</p>
                  </section>
                </ContactGrid>
              </ContainerContentStandard>
            </Container>
          </TextOnlyPageContainer>
        </Layout>
      </>
    );
  }
}
